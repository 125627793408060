
/***************** MOSTRAR NOTIFICACIONES **********************/

var show_alert = document.getElementById('show-alert');
if(show_alert != null){
    if(show_alert.classList.contains("error")){
        showAlertError();
    }
    if(show_alert.classList.contains("success")){
        showAlertSuccess();
    }

    function showAlertError(){
        if(show_alert != null){
            swal.fire(
                'Error!',
                show_alert.innerText,
                'error'
            )
        }
    }

    function showAlertSuccess(){
        if(show_alert != null){
            swal.fire(
                'Correcto!',
                show_alert.innerText,
                'success'
            )
        }
    }
}
/*************************************************************/


/********************** ELIMINAR ELEMENTOS ***********************/

/* Selecciona el elemento para eliminar en cada vista (team, player, etc)
    y mostrar el mensaje de confirmacion  */
var btn_delete_general_element  = document.getElementById("btn-delete-general-element");
if(btn_delete_general_element != null){
    var form_delete_general_element = document.getElementById("form-delete-general-element");
    btn_delete_general_element.addEventListener("click", showDeleteConfirm);

    function showDeleteConfirm(){
        swal.fire({
            title: 'Eliminar elemento',
            text: "¿Estas seguro de eliminar este elemento?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '¡Sí, borrarlo!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                    submitFormDeleteGeneralElement()
            }
        })
    }

    function submitFormDeleteGeneralElement(){
        form_delete_general_element.submit();
    }
}

/***************************************************************/

/******************************* Confirmacion de editar y guardar quiniela ******************************/

var btn_predictions_submit = document.getElementById("form-prediction-save");
if(btn_predictions_submit != null){
    btn_predictions_submit.addEventListener('submit', function(e){
        e.preventDefault();
        
        var matches_labels = document.querySelectorAll('.form-check div label');
        var matches_inputs = document.querySelectorAll('.form-check div input');
        
        var alert_text = '';
        
        var aux = 1;
        matches_labels.forEach(label => {

            if(aux % 3 == 1){

                if(matches_inputs[aux-1].checked == true){
                    var seleccionada = 'text-danger';
                }else
                {
                    var seleccionada = '';
                }
                alert_text += '<p class="h6 col-5 ' + seleccionada + '">' +  label.innerText + '</p>';
            }
            if(aux % 3 == 2 ){
                if(matches_inputs[aux-1].checked == true){
                    var seleccionada = 'text-danger';
                }else
                {
                    var seleccionada = '';
                }
                alert_text += '<p class="h6 col-2 ' + seleccionada + '">' +  label.innerText + '</p>';            }
            if(aux % 3 == 0 ){
                if(matches_inputs[aux-1].checked == true){
                    var seleccionada = 'text-danger';
                }else
                {
                    var seleccionada = '';
                }
                alert_text += '<p class="h6 col-5 ' + seleccionada + '">' +  label.innerText + '</p>';            }
            aux++;
        });
        

        var name_player = document.getElementById('player_name').value;
        

        swal.fire({
            title: '<div class="w-100 m-0 p-0">' + 
                        '<p class="h1 d-block text-primary mb-3">' +
                             name_player +
                        '</p>'+
                        '<div class="row m-0 p-0">' + 
                              alert_text + 
                        '</div>'+
                    '</div>',
            text: '',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '¡Sí, Guardar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                btn_predictions_submit.submit();
            }else{
                
            }
        })  
    });
}

/***************************************************************/